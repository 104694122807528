'use client'

import dynamic from 'next/dynamic'
import { redirect } from 'next/navigation'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { useProposalContext } from '@/context/ProposalContext'

import usePageLayout from '@/hooks/useLayout'
import useLazyLoad from '@/hooks/useLazyLoad'

import { Hero } from '@/sections'

import { Banner, BannerButtons, If } from '@/components'

const Paywall = dynamic(() =>
  import('@/components/Paywall/Paywall').then((mod) => mod.Paywall)
)

const Plans = dynamic(() =>
  import('@/sections/Plans/Plans').then((mod) => mod.Plans)
)

const Help = dynamic(() =>
  import('@/sections/Help/Help').then((mod) => mod.Help)
)

const Footer = dynamic(() =>
  import('@/components/Footer/Footer').then((mod) => mod.Footer)
)

const Solutions = dynamic(() =>
  import('./sections/Solutions/Solutions').then((mod) => mod.Solutions)
)

const Advantages = dynamic(() =>
  import('./sections/Advantages/Advantages').then((mod) => mod.Advantages)
)

const PlansList = dynamic(() =>
  import('./sections/Plans/Plans').then((mod) => mod.Plans)
)

const Representative = dynamic(() =>
  import('./sections/Representative/Representative').then(
    (mod) => mod.Representative
  )
)

const Results = dynamic(() =>
  import('./sections/Results/Results').then((mod) => mod.Results)
)

const redirectToHome = () => {
  const isOrganic = process.env.NEXT_PUBLIC_BUILD_TYPE === 'organic'

  // eslint-disable-next-line no-undef
  if (!isOrganic && environmentId === 'production') {
    redirect('/')
  }
}

export default function Home() {
  const lazyLoad = useLazyLoad(50)
  redirectToHome()
  usePageLayout()

  const { isPaywallOpen } = useProposalContext()

  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  return (
    <main>
      {isPaywallOpen && <Paywall />}

      <Banner
        isAnimated
        align="center"
        text="A Boa Vista mudou e agora é Equifax | BoaVista!"
      />

      <Hero
        variant="organic"
        title="Ajudamos sua empresa a tomar decisões de crédito mais seguras e reduzir o risco de inadimplência."
        subtitle="Não importa o seu modelo de negócio, nós podemos te ajudar"
      />

      {!isMobile && (
        <>
          <Plans key="plans-form_v2--b" />
          <Advantages />
        </>
      )}

      <If condition={lazyLoad}>
        {isMobile && (
          <>
            <Plans key="plans-form_v2--b" />
            <Advantages />
          </>
        )}

        <Solutions />
        <PlansList planType="prepaid" />
        <Representative />
        <Results />
        <Help shouldRenderFilters />
        <Footer hasBannerSpacement={true} />
      </If>

      <Banner
        isFixed
        hasBorder
        hideTextOnMobile
        text="Central de vendas Equifax | BoaVista"
      >
        <BannerButtons />
      </Banner>
    </main>
  )
}
